<template>
  <div class="square-button" :class="{'is-selected': selected}" v-on="$listeners">
    <img :src="$asset(image)" :alt="text">
    <div>
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SquareButton",
  props: {
    text: String,
    image: String,
    selected: Boolean,
  }
}
</script>

<style scoped lang="scss">
  .square-button {
    background-color: var(--white);
    width: 89px;
    /*height: 89px;*/
    min-width: 89px;
    min-height: 89px;
    padding: 4px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
    margin: 10px;
    user-select: none;
    cursor: pointer;
    transition: background-color .2s ease, color .2s ease;
      display: flex;
      flex-direction: column;
    > img {
      height: 53px;
    }
    &.is-selected {
      color: var(--secondary);
    }
    &:hover, &:focus {
      background-color: var(--input-background-secondary);
    }
  }
</style>
