<template>
  <div class="restaurants--empty">
    <h3 class="restaurants--empty--header">Brak restauracji w pobliżu</h3>
    <p class="restaurants--empty--content" v-if="!noActive">Wybrany adres znajduje się poza obszarem dostaw</p>
    <p class="restaurants--empty--content" v-else>Brak dostępnych restauracji w podanym obszarze dostaw</p>
  </div>
</template>

<script>
export default {
  name: "RestaurantsEmpty",
  props: {
    noActive: Boolean,
  }
}
</script>

<style scoped lang="scss">
  .restaurants--empty {
    background-color: var(--white);
    padding: 15px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
    width: fit-content;
    margin: 10px;
    &--header {
      margin: 0;
    }
    &--content {
      margin: 0;
    }
  }
</style>
