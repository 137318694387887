<template>
  <div class="page page--home animated_content">
    <sequential-entrance animation="animate__fadeInUp" tag="div">
      <DownloadFromStore class="animate__animated"/>
      <StickyHeader class="animate__animated" with-address with-menu/>
      <h2 class="animate__animated home_header">{{ greetings }}</h2>
      <h3 class="animate__animated home_subheader">Co chciałbyś zjeść?</h3>

      <div class="square_button_container animate__animated" :class="{'with-shadow': showShadow}">
        <sequential-entrance animation="animate__fadeInRight" tag="div">
          <SquareButton v-for="type in types" :key="type.id" :text="type.name" :image="type.image.path" :selected="type.id === selectedType" @click="$store.dispatch('selectType', {typeId: type.id})" class="animate__animated"/>
        </sequential-entrance>
      </div>
      <div class="restaurants_container no_animation">
        <sequential-entrance animation="animate__fadeInUp" tag="div">
<!--          <transition-group appear enter-active-class="animate__fadeInUp" leave-active-class="animate__fadeOutUp" tag="div">-->
          <template v-if="restaurants.length > 0">
            <RestaurantBox v-for="restaurant in restaurants" :key="restaurant.id" :restaurant="restaurant" class="animate__animated"/>
          </template>
          <template v-else-if="restaurantsClosed.length > 0">
            <RestaurantsEmpty class="animate__animated" no-active/>
          </template>
          <template v-else>
            <RestaurantsEmpty class="animate__animated"/>
          </template>
<!--          </transition-group>-->
        </sequential-entrance>
      </div>
      <div class="restaurants_container no_animation">
        <sequential-entrance animation="animate__fadeInUp" tag="div">
          <RestaurantBox v-for="restaurant in restaurantsClosed" :key="restaurant.id" :restaurant="restaurant" closed class="animate__animated"/>
        </sequential-entrance>
      </div>
      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </sequential-entrance>
    <Drawer/>
    <SearchAddress/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from "@/components/Logo";
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";
import SquareButton from "@/components/SquareButton";
import RestaurantsEmpty from "@/parts/RestaurantsEmpty";
import Drawer from "@/parts/Drawer";
import SearchAddress from "@/parts/SearchAddress";
import RestaurantBox from "@/parts/RestaurantBox";
import DownloadFromStore from "@/parts/DownloadFromStore";

export default {
  name: 'Home',
  components: {
    DownloadFromStore,
    RestaurantBox,
    SearchAddress,
    Drawer,
    RestaurantsEmpty,
    SquareButton,
    Logo,
    StickyHeader,
    PlainFooter,
  },
  computed: {
    ...mapGetters([
      'types',
      'selectedType',
      'restaurants',
      'restaurantsClosed',
      'isLogged',
      'user',
    ]),
    greetings() {
      if (this.isLogged) {
        const first_name = this.user.name.split(' ')[0];
        return `Cześć, ${first_name}!`;
      }
      return 'Cześć';
    }
  },
  data() {
    return {
      showShadow: false
    };
  },
  methods: {
    onScroll(e) {
      if (window.document.scrollingElement.scrollTop >= 120) {
        if (this.showShadow === false) {
          this.showShadow = true;
        }
      } else {
        if (this.showShadow === true) {
          this.showShadow = false;
        }
      }
    }
  },
  created() {

  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }
}
</script>

<style scoped lang="scss">
  .home_header {
    margin-bottom: 10px;
  }
  .home_subheader {
    font-weight: normal;
    margin-top: 10px;
    color: var(--text-secondary);
  }
  .square_button_container {
    position: sticky;
    top: 60px;
    z-index: 9;
    background-color: var(--default-background);
      display: flex;
      flex-direction: row;
      justify-content: center;
    &.with-shadow {
      box-shadow: var(--shadow-two);
    }
    > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: auto;
      overflow-x: auto;
    }
  }
  .restaurants_container > div {
    display: grid;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    grid-template-columns: max-content;
    align-items: stretch;
    justify-items: stretch;
    @media screen and (min-width: 1260px) {
      grid-template-columns: max-content max-content;
    }
  }
  .flex-fill {
    flex: 1;
  }
</style>
