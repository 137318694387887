<template>
  <div class="restaurant_box" @click="goToRestaurant">
    <div class="image_main">
      <img v-if="restaurant.image_main" :src="$asset(restaurant.image_main.path)" :alt="restaurant.name">
    </div>
    <div class="image_logo">
      <img v-if="restaurant.image_logo" :src="$asset(restaurant.image_logo.path)" :alt="`Logo ${restaurant.name}`">
    </div>
    <div class="restaurant_box_content">
      <div class="title_line">
        <span class="title">{{ restaurant.name }}</span>
        <div class="elem" title="Ocena restauracji" v-if="restaurant.rate > 0">
          <div class="icon">
            <img src="@/assets/images/icons/like-new.svg" alt="Like">
          </div>
          <div class="content">
<!--            {{ restaurant.number_opinions }}-->
            {{ (Math.round(restaurant.rate * 10) / 10).toFixed(1) }}
            <span v-if="restaurant.number_opinions > 0">({{ restaurant.number_opinions }})</span>
          </div>
        </div>
      </div>
      <p class="restaurant_description">
        {{ restaurant.description }}
      </p>
    </div>
    <div class="closed_container" v-if="closed">
      <div class="closed_text">{{ (restaurant.today_open === 'Nieczynne' || restaurant.today_open === null) ? 'Dzisiaj nieczynne' : `Dzisiaj otwarte: ${restaurant.today_open}` }}</div>
    </div>
    <div class="closed_container" v-if="!closed && restaurant.active_deliveries === 0">
      <div class="closed_text">Dostawy wstrzymane</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RestaurantBox",
  props: {
    restaurant: Object,
    closed: Boolean,
  },
  methods: {
    goToRestaurant() {
      this.$router.push({name: 'Restaurant', params: {id: this.restaurant.id}});
    },
  },
}
</script>

<style scoped lang="scss">
  .restaurant_box {
    position: relative;
    background-color: var(--white);
    border-radius: var(--border-radius);
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
    /*width: fit-content;*/
    width: auto;
    max-width: 600px;
    margin: 10px;
    cursor: pointer;
    transition: background-color .2s ease, color .2s ease;
    .image_main {
      height: 200px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .image_logo {
      display: flex;
      width: 100%;
      top: 145px;
      left: 10px;
      z-index: 1;
      position: absolute;
      align-items: flex-start;
      img {
        object-fit: contain;
        width: 70px;
        height: 70px;
        border-radius: 70px;
        background-color: var(--white);
        border-width: 1px;
        border-style: solid;
        border-color: var(--border);
      }
    }
    .restaurant_box_content {
      padding: 15px;
      position: relative;
      .title_line {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .title {
          font-size: 18px;
          font-weight: 500;
        }
      }
      .restaurant_description {
        text-align: left;
        margin: 5px 0;
      }
    }

    .closed_container {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, .3);
      .closed_text {
        width: 100%;
        background-color: rgba(0, 0, 0, .3);
        color: var(--white);
        padding: 4px 0;
      }
    }

    .elem {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 8px;
      position: absolute;
      right: 15px;
      top: 10px;
      .icon {
        img {

        }
      }
      .content {
        margin-left: 6px;
      }
    }

    &:hover, &:focus {
      background-color: var(--secondary);
      color: var(--white);
    }

  }
</style>
